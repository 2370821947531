import { useOptimizationStore } from '@/stores/optimization'
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const componentInProjectGuardMiddleware = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to.meta || !to.meta.requiresComponentInProject) {
    return next()
  }

  const componentInProjectId: number|null = to.params.componentInProjectId ? parseInt(to.params.componentInProjectId as string) : null
  if (!componentInProjectId) {
    return next({ name: 'page-not-found' })
  }

  const optimizationStore = useOptimizationStore()

  if (!optimizationStore.selectedComponentInProject || componentInProjectId !== optimizationStore.selectedComponentInProject?.id) {
    try {
      await optimizationStore.selectComponentInProject(componentInProjectId)
    } catch (error) {
      return next({ name: 'page-not-found' })
    }
  }
  next()
}
