<script setup lang="ts">
import type { ConversationSource } from '@aedifion.io/aedifion-api'

interface Props {
  source: ConversationSource
}

const props = defineProps<Props>()

function handleSourceClick (url: string) {
  window.open(url, '_blank')
}

function getMeaningfulSourceName (source: ConversationSource) {
  const lastSlashIndex = source.url.lastIndexOf('/')
  return `${source.name}: ${source.url.slice(lastSlashIndex + 1)}`
}

function getSourceIcon () {
  if (props.source.url.includes('pdf')) {
    return 'fa:far fa-file-lines'
  }

  return 'fa:far fa-arrow-up-right'
}
</script>

<template>
  <div
    class="tw-rounded-full tw-w-fit tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border tw-px-3 tw-py-[6px] text-body-1 text-neutral-darken3"
  >
    <button
      @click="handleSourceClick(source.url)"
    >
      <v-icon
        class="tw-mr-2"
        size="14"
      >
        {{ getSourceIcon() }}
      </v-icon>
      <span
        data-testid="source-name"
        v-text="getMeaningfulSourceName(source)"
      />
    </button>
  </div>
</template>
