<template>
  <v-card
    class="pa-6 mx-auto tw-h-full aedifion-hover"
    :to="projectLink"
  >
    <v-skeleton-loader
      v-if="isLoading"
      type="image"
    />
    <v-img
      v-else
      :aspect-ratio="16 / 9"
      class="rounded mouse-hover"
      height="194"
      cover
      lazy-src="/img/dummy-image.jpg"
      :src="project.avatar || '/img/dummy-image.jpg'"
    />

    <v-card-title
      class="card-title text-h5 text-truncate mouse-hover px-0 pt-0 pb-1 mt-6"
      style="display: block"
    >
      <v-skeleton-loader
        v-if="isLoading"
        type="heading"
      />
      <span
        v-else
        v-text="project.name"
      />
    </v-card-title>

    <v-card-subtitle class="card-description pa-0 mt-0 text-truncate tw-h-[20px]">
      <v-skeleton-loader
        v-if="isLoading"
        class="pt-2"
        type="sentences"
      />
      {{ formattedDescription }}
    </v-card-subtitle>

    <v-card-actions class="pt-6 pb-0 px-0">
      <ProjectStatus :project-id="project.id" />
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { isAssetOverviewSetUp } from '@/utils/helpers/viewStatus'
import { ProjectCardData } from '@/vuex/projects/types'
import ProjectStatus from '@/views/Portfolio/ProjectStatus.vue'
import { RouteLocation } from 'vue-router'
import { formatNetFloorArea } from '@/filters/buildingAttributes'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'

export default defineComponent({
  name: 'ProjectCard',

  components: {
    ProjectStatus,
  },

  props: {
    project: {
      required: true,
      type: Object as PropType<ProjectCardData>,
    },
  },

  computed: {
    isLoading (): boolean {
      return this.$store.getters['projects/isLoading']
    },

    projectLink (): RouteLocation {
      return this.$router.resolve({
        name: isAssetOverviewSetUp(this.project.id) ? 'asset-overview' : 'optimization-optimizations',
        params: {
          project: this.project.id.toString(),
        },
      })
    },

    formattedDescription (): string {
      if (this.project.buildingComponent?.attributes?.length === 0 || this.isLoading) return ''

      let netFloorArea = getComponentAttribute(this.project.buildingComponent, 'B+NFA')?.value
      netFloorArea = netFloorArea ? formatNetFloorArea(netFloorArea) : ''

      let buildYear = getComponentAttribute(this.project.buildingComponent, 'B+BUILD_YEAR')?.value
      buildYear = buildYear ? buildYear.split('.')[0] : ''

      const descriptionParts = [
        // TODO: Add city name here
        netFloorArea,
        buildYear,
      ].filter(part => part !== '')

      return descriptionParts.join((' · '))
    },
  },

  methods: {
    navigateToRoute (route: string) {
      this.$router.push(route)
    },
  },
})
</script>

<style lang="sass" scoped>
.card-description
  display: block
  overflow: hidden

.v-skeleton-loader__button
  width: 130px

.mouse-hover:hover
  cursor: pointer
</style>
