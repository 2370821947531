import i18n, { AvailableLocales } from '@/i18n'
import { NumberOptions } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'

/**
 * Returns the user's currency system
 * @returns The user's currency system
*/
export function getUserCurrencySymbol (): string {
  const userStore = useUserStore()
  const { userDetails } = storeToRefs(userStore)
  switch (userDetails.value?.currency_system) {
    case 'CHF':
      return 'Fr.'
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    default:
      return ''
  }
}

export function formatDate (date: Date|string): string {
  return new Date(date).toLocaleString(i18n.global.locale.value, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  })
}

export function formatDateTime (dateTime: Date|string): string {
  return new Date(dateTime).toLocaleString(i18n.global.locale.value).replace(/\u202F/, ' ')
}

export function formatSubAndSuper (value: string): string {
  const dictionary: { [key: string]: string } = {
    '\\^2': '<sup>2</sup>',
    '\\^3': '<sup>3</sup>',
    CO2: 'CO<sub>2</sub>',
    H2O: 'H<sub>2</sub>O',
  }
  const searchRegEx = new RegExp(Object.keys(dictionary).join('|'), 'gi')
  const result = value.replace(searchRegEx, (matched: string) => {
    return dictionary[matched.replace('^', '\\^')]
  })
  return result
}

/**
 * An optional set of options for formatting values.
 * - `displayUnit`: whether to actually display the unit or only use it to
 * determine how the value should be formatted.
 * - `fallbackValue`: the value that should be returned in case the value is
 * nullish or NaN.
 * - `subAndSuperUnit`: whether to use `formatSubAndSuper()` to format the unit.
 * - `unit`: the unit that should be appended to the value, also used to infer
 * how the value should be formatted.
 */
type ValueFormattingOptions = {
  displayUnit?: boolean
  fallbackValue?: string
  integer?: boolean
  subAndSuperUnit?: boolean
  unit?: string|null
}

const CURRENCIES = ['€', 'EUR', '\\$', 'USD', 'Fr.', 'CHF']
const currencyRegExp = new RegExp(`^(${CURRENCIES.join('|')})`)
const percentageRegExp = /^%/

// TODO: rename `formatValue` to `formatAnalysisResultValue` and replace its
// usage with `formatNumber`, except for analysis results.
// see https://aedifion.atlassian.net/browse/FE-1827

/**
 * Formats the number passed as first argument using the currently defined
 * locale with the right amount of decimal places.
 * - Integers without unit are formatted without a decimal part,
 * - percentages are formatted with 1 decimal place,
 * - currencies and numbers between -10 and 10 are formatted with 2 decimal
 * places, and
 * - the other numbers are formatted with 4 significant digits.
 * @param value The value that should be formatted.
 * @param options An optional set of options for the formatting.
 * - `displayUnit` (boolean): whether to actually display the unit that was
 * passed or only use it to determine how the value should be formatted.
 * Defaults to `true`.
 * - `fallbackValue` (string): the value that should be returned in case `value`
 * is nullish, NaN or infinite. Defaults to `''`.
 * - `integer` (boolean): whether the value should be interpreted as an integer.
 * The decimal part of an integer is not displayed. Defaults to `false`.
 * - `subAndSuperUnit` (boolean): whether to use `formatSubAndSuper()` to format
 * the unit. Defaults to `false`.
 * - `unit` (string): the unit that should be appended to the value, also used
 * to infer how the value should be formatted. Defaults to `''`.
 * @returns The formatted value.
 */
export function formatValue (value: number|null|undefined, options: ValueFormattingOptions = {}): string {
  options.displayUnit = options.displayUnit ?? true
  options.fallbackValue = options.fallbackValue ?? ''
  options.integer = options.integer ?? false
  options.subAndSuperUnit = options.subAndSuperUnit ?? false
  options.unit = options.unit ?? ''

  if (value === null || value === undefined || !Number.isFinite(value)) {
    return options.fallbackValue
  }

  const i18nOptions: NumberOptions<string, AvailableLocales> = {}

  if (options.integer || (!options.unit && Number.isInteger(value))) {
    i18nOptions.minimumFractionDigits = 0
    i18nOptions.maximumFractionDigits = 0
  } else if (options.unit && percentageRegExp.test(options.unit.trim())) {
    i18nOptions.minimumFractionDigits = 1
    i18nOptions.maximumFractionDigits = 1
  } else if ((options.unit && currencyRegExp.test(options.unit.trim())) || Math.abs(value) < 10) {
    i18nOptions.minimumFractionDigits = 2
    i18nOptions.maximumFractionDigits = 2
  } else {
    i18nOptions.minimumSignificantDigits = 4
    i18nOptions.maximumSignificantDigits = 4
  }

  if (options.displayUnit) {
    return `${i18n.global.n(value, i18nOptions)} ${options.subAndSuperUnit ? formatSubAndSuper(options.unit) : options.unit}`.trim()
  } else {
    return i18n.global.n(value, i18nOptions)
  }
}

/**
 * Formats the number passed as first argument using the currently defined
 * locale with the right amount of decimal places.
 * - Integers and numbers with 4 digits or more before the decimal separator are
 * formatted without a decimal part,
 * - numbers with 3 digits before the decimal separator are formatted with 1
 * decimal place,
 * - numbers with 1 or 2 digits before the decimal separator are formatted with
 * 2 decimal place, and
 * - numbers between -1 and 1 are formatted with 2 significant digits.
 * @param value The value that should be formatted.
 * @param options An optional set of options for the formatting.
 * - `fallbackValue` (string): the value that should be returned in case `value`
 * is nullish, NaN or infinite. Defaults to `''`.
 * - `integer` (boolean): whether the value should be interpreted as an integer.
 * The decimal part of an integer is not displayed. Defaults to `false`.
 * - `subAndSuperUnit` (boolean): whether to use `formatSubAndSuper()` to format
 * the unit. Defaults to `false`.
 * - `unit` (string): the unit that should be appended to the value. Defaults to
 * `''`.
 * @returns the formatted value.
 */
export function formatNumber (value: number | null | undefined, options: Exclude<ValueFormattingOptions, 'displayUnit'> = {}): string {
  const {
    fallbackValue = '',
    integer = false,
    subAndSuperUnit = false,
  } = options
  const unit = options.unit ?? ''

  if (typeof value !== 'number' || !Number.isFinite(value)) {
    return fallbackValue
  }

  const i18nOptions: NumberOptions<string, AvailableLocales> = {}

  if (integer || Number.isInteger(value) || Math.abs(value) >= 1000) {
    i18nOptions.minimumFractionDigits = 0
    i18nOptions.maximumFractionDigits = 0
  } else if (Math.abs(value) >= 100) {
    i18nOptions.minimumFractionDigits = 1
    i18nOptions.maximumFractionDigits = 1
  } else if (Math.abs(value) >= 1) {
    i18nOptions.minimumFractionDigits = 2
    i18nOptions.maximumFractionDigits = 2
  } else {
    i18nOptions.minimumSignificantDigits = 2
    i18nOptions.maximumSignificantDigits = 2
  }

  return `${i18n.global.n(value, i18nOptions)} ${subAndSuperUnit ? formatSubAndSuper(unit) : unit}`.trim()
}

/**
 * Parses a localized number string into a float.
 *
 * This function handles different locale formats for numbers.
 * For the 'de' (German) locale, it replaces periods with empty strings
 * and commas with periods before parsing the float.
 * For other locales, it replaces commas with empty strings before parsing the float.
 *
 * @param locale - The locale of the number string (e.g., 'de' for German).
 * @param value - The localized number string to be parsed.
 * @returns The parsed float value.
 */
export function parseLocalizedNumber (locale: string, value: string) {
  if (locale === 'de') {
    return parseFloat(value.replace(/\./g, '').replace(/,/, '.'))
  }

  return parseFloat(value.replace(/,/g, ''))
}
