import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { Alert, AlertsResponse, SEVERITY, STATUS } from '@/services/alerta/types'
import { IncidentConfiguration } from '@/vuex/status_and_alerts/types'
import vuexStore from '@/vuex'
import { ProjectWithContext } from '@aedifion.io/aedifion-api'
import { ProjectValue, ProjectId } from '@/utils/types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import i18n from '@/i18n'
import { Alerts } from '@/services/alerta'
import { reportError } from '@/utils/helpers/errors'

export type AlertsState = {
  incidentAlerts: Record<ProjectId, Alert[]>;
  loadingIncidentAlerts: ProjectId[];
  statusCounts: Record<ProjectId, Partial<Record<STATUS, number>>>
}

type ProjectIdFunction<T> = (projectId: number) => T

type StatusCount = Partial<Record<STATUS, number>>

/**
 * Takes a list of incident configurations and creates a list of pairs of their categories.
 *
 * Note: This makes use of slice checking against the length of the array. Ie., if the list has an uneven number of elements,
 * the last item in the result only contains one category.
 *
 * @param incidentConfigurations The incident configurations to convert.
 * @returns A list of pairs of incident configuration categories.
 */
function toCategoryPairs (incidentConfigurations: IncidentConfiguration[]): Array<string[]> {
  const incidentCategories: string[] = incidentConfigurations.map(item => item.category)
  const result: Array<string[]> = []
  for (let i = 0; i < incidentCategories.length; i += 2) {
    result.push(incidentCategories.slice(i, i + 2))
  }
  return result
}

export const useAlertsStore = defineStore('alerts', () => {
  const incidentAlerts = ref<AlertsState['incidentAlerts']>({})
  const loadingIncidentAlerts = ref<AlertsState['loadingIncidentAlerts']>([])
  const statusCounts = ref<AlertsState['statusCounts']>({})

  const areIncidentAlertsOfProjectLoaded = computed<ProjectIdFunction<boolean>>(() => {
    return (projectId: number) => {
      return projectId in incidentAlerts.value
    }
  })

  const incidentAlertCountsOfProject = computed<ProjectIdFunction<StatusCount|null>>(() => {
    return (projectId: number) => {
      return statusCounts.value[projectId] ?? null
    }
  })

  const incidentAlertsOfProject = computed<ProjectIdFunction<Alert[]|null>>(() => {
    return (projectId: number) => {
      return incidentAlerts.value[projectId] ?? null
    }
  })

  const isLoadingAlertsOfProject = computed<ProjectIdFunction<boolean>>(() => {
    return (projectId: number) => {
      return loadingIncidentAlerts.value.includes(projectId)
    }
  })

  function clear () {
    incidentAlerts.value = {}
    loadingIncidentAlerts.value = []
    statusCounts.value = {}
  }

  function _setLoadingIncidentAlerts (loading: ProjectValue<boolean>) {
    const { value: isLoading, projectId } = loading

    if (isLoading) {
      if (!loadingIncidentAlerts.value.includes(projectId)) {
        loadingIncidentAlerts.value.push(projectId)
      }
    } else {
      loadingIncidentAlerts.value = loadingIncidentAlerts.value.filter(id => id !== projectId)
    }
  }

  function _clearProjectData (projectId: number) {
    delete incidentAlerts.value[projectId]
    delete statusCounts.value[projectId]
  }

  function _addProjectIncidentAlerts (projectIncidentAlerts: ProjectValue<Alert[]>) {
    if (projectIncidentAlerts.projectId in incidentAlerts.value) {
      incidentAlerts.value[projectIncidentAlerts.projectId].push(...projectIncidentAlerts.value)
    } else {
      incidentAlerts.value[projectIncidentAlerts.projectId] = projectIncidentAlerts.value
    }
  }

  function _addProjectStatusCounts (projectStatusCounts: ProjectValue<Partial<Record<STATUS, number>>>) {
    if (projectStatusCounts.projectId in statusCounts.value) {
      const statusCountsForProject = statusCounts.value[projectStatusCounts.projectId]
      for (const status in projectStatusCounts.value) {
        if (status in statusCountsForProject) {
          statusCountsForProject[status as STATUS]! += projectStatusCounts.value[status as STATUS]!
        } else {
          statusCountsForProject[status as STATUS] = projectStatusCounts.value[status as STATUS]
        }
      }
    } else {
      statusCounts.value[projectStatusCounts.projectId] = projectStatusCounts.value
    }
  }

  async function fetchIncidentAlertsForProject (projectId: number) {
    const project = vuexStore.getters['projects/project'](projectId) as ProjectWithContext|null
    if (
      !project?.project ||
      !vuexStore.getters['building_analyses/isBuildingComponentOfProjectLoaded'](projectId) ||
      vuexStore.getters['building_analyses/projectUsesSampleData'](projectId)
    ) {
      return
    }

    const incidentConfigurations = vuexStore.getters['building_analyses/incidentConfigurationsOfProject'](projectId) as IncidentConfiguration[]

    if (!Array.isArray(incidentConfigurations) || incidentConfigurations.length === 0) {
      return
    }

    const incidentCategoryPairs = toCategoryPairs(incidentConfigurations)

    _setLoadingIncidentAlerts({ projectId, value: true })
    try {
      const alertsResponses: AlertsResponse[] = await Promise.all(
        incidentCategoryPairs.map((categoryPair: string[]) => {
          return Alerts.getAlerts(
            1,
            [SEVERITY.CRITICAL, SEVERITY.MAJOR],
            [STATUS.OPEN, STATUS.ACK],
            undefined,
            { key: 'project_handle', value: project.project!.handle! },
            categoryPair.length === 2 ? `attributes.category:(/${categoryPair[0]}/ OR /${categoryPair[1]}/)` : `attributes.category:(/${categoryPair[0]}/)`,
          )
        }),
      )
      _clearProjectData(projectId)
      for (const alertsResponse of alertsResponses) {
        _addProjectIncidentAlerts({ projectId, value: alertsResponse.alerts })
        _addProjectStatusCounts({ projectId, value: alertsResponse.statusCounts })
      }
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.alerts') })}`)
      reportError(error)
    } finally {
      _setLoadingIncidentAlerts({ projectId, value: false })
    }
  }

  return {
    incidentAlerts,
    loadingIncidentAlerts,
    statusCounts,
    areIncidentAlertsOfProjectLoaded,
    incidentAlertCountsOfProject,
    incidentAlertsOfProject,
    isLoadingAlertsOfProject,
    clear,
    fetchIncidentAlertsForProject,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAlertsStore, import.meta.hot))
}
