<template>
  <div class="tw-w-[44px] tw-h-[56px] tw-flex tw-flex-col tw-items-center tw-mx-6 tw-my-3 tw-px-4 tw-py-3 tw-rotate-90 tw-gap-1 tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border tw-bg-white tw-rounded-2xl">
    <div
      v-for="(_n, index) in 3"
      :key="index"
      class="dot tw-mx-4 bg-neutral-darken3 text-body-1 tw-rounded-full tw-size-2"
    />
  </div>
</template>

<style scoped>
.dot {
  animation: bounce 1.5s infinite ease-in-out;
  font-feature-settings: 'ss01' on, 'ss04' on, 'cv01' on;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%, 60%, 100% {
    transform: translateX(0);
  }
  30% {
    transform: translateX(-5px);
  }
}
</style>
