import { AnalysisFunction, AnalyticsResultsForComponent, AnalyticsResultsForComponentResultIds, ComponentInProjectWithContext, InstanceConfig } from '@aedifion.io/aedifion-api'
import { computed, ref, watch } from 'vue'
import { useAnalyticsApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { useRoute, useRouter } from 'vue-router'
import { defineStore } from 'pinia'
import i18n from '@/i18n'
import moment from 'moment'
import { reportError } from '@/utils/helpers/errors'
import { Samplerate } from '@/vuex/data_points_view/types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { textForLocale } from '@/utils/helpers/locale'
import { useAppStore } from './app'
import { useUserStore } from './user'
import { validateNotNullish } from '@/utils/helpers/validate'
import vuexStore from '@/vuex'
import { useAnalysisFunctionsStore } from './analysisFunctions'

export type PlotViewDateRange = [string, string]

export type CondensedAnalysisFunctionWithResult = {
  enabled: boolean;
  function_alphanumeric_id: string;
  function_id: number;
  function_names: {
    de: string;
    en: string;
  }
  instance_id: number|null;
  interpretation: string|null;
  result_id: string|null;
  signal_color: string;
}

function getAnalysisResultForAnalysisFunction (alphanumericId: string, results: AnalyticsResultsForComponentResultIds[]) {
  return results.find((result) => result.function_name === alphanumericId) || null
}

function getInstanceForResult (result: AnalyticsResultsForComponentResultIds, instances: InstanceConfig[]) {
  return instances.find((instance) => instance.id === result.instance_id)
}

function getInstanceForAnalysisFunctionId (analysisFunctionId: number, instances: InstanceConfig[]) {
  return instances.find((instance) => instance.config.analysisfunction_id === analysisFunctionId)
}

function combineAnalysisFunctionsWithInstanceResults (
  analysisFunctions: AnalysisFunction[],
  instances: InstanceConfig[],
  analysisResults: AnalyticsResultsForComponentResultIds[],
) {
  return analysisFunctions.map((analysisFunction) => {
    const analysisResult = getAnalysisResultForAnalysisFunction(analysisFunction.alphanumeric_id, analysisResults)

    let instance: InstanceConfig | null = null
    if (analysisResult) {
      instance = getInstanceForResult(analysisResult, instances) ?? null
    } else {
      instance = getInstanceForAnalysisFunctionId(analysisFunction.id, instances) ?? null
    }

    return {
      enabled: (instance?.config.enabled === true || (!!instance && instance?.config.enabled === undefined)) ?? false,
      function_alphanumeric_id: analysisFunction.alphanumeric_id!,
      function_id: analysisFunction.id!,
      function_names: {
        de: analysisFunction.nameDE!,
        en: analysisFunction.nameEN!,
      },
      instance_id: instance?.id ?? null,
      interpretation: analysisResult?.interpretation ?? null,
      result_id: analysisResult?.result_id ?? null,
      signal_color: analysisResult?.signal_color || 'neutral',
    } as CondensedAnalysisFunctionWithResult
  })
}

export const useOptimizationStore = defineStore('optimization', () => {
  const route = useRoute()
  const router = useRouter()

  const appStore = useAppStore()
  const userStore = useUserStore()
  const analysisFunctionsStore = useAnalysisFunctionsStore()
  const projectApiStore = useProjectApiStore()
  const analyticsApiStore = useAnalyticsApiStore()

  const dateRange = ref<PlotViewDateRange>([
    moment.utc().subtract(7, 'd').format('YYYY-MM-DD'),
    moment.utc().format('YYYY-MM-DD'),
  ])
  const followAnalysisPeriod = ref<boolean>(false)
  const loadingComponentInProject = ref<boolean>(false)
  const loadingComponentsAnalysisResults = ref<boolean>(false)
  const search = ref<string | null>(null)
  const selectedComponentInProject = ref<ComponentInProjectWithContext | null>(null)
  const selectedComponentInProjectResults = ref<AnalyticsResultsForComponent | null>(null)
  const selectedInstanceId = ref<number | null>(null)
  const selectedResultId = ref<string | null>(null)
  const samplerate = ref<Samplerate>('auto')
  const hiddenPinsHashIds = ref<string[]>([])

  function clear () {
    dateRange.value = [
      moment.utc().subtract(7, 'd').format('YYYY-MM-DD'),
      moment.utc().format('YYYY-MM-DD'),
    ]
    followAnalysisPeriod.value = false
    loadingComponentInProject.value = false
    loadingComponentsAnalysisResults.value = false
    search.value = null
    selectedComponentInProject.value = null
    selectedComponentInProjectResults.value = null
    selectedInstanceId.value = null
    selectedResultId.value = null
    samplerate.value = 'auto'
    hiddenPinsHashIds.value = []
  }

  watch(() => followAnalysisPeriod.value, async (newValue, oldValue) => {
    await router.isReady()
    const query = route.query
    if (oldValue !== newValue && newValue === true) {
      router.replace({ query: { ...query, followAnalysisPeriod: 'true' } })
    } else if (oldValue !== newValue && newValue === false) {
      router.replace({ query: { ...query, followAnalysisPeriod: undefined } })
    }
  })

  watch(() => search.value, async (newValue, oldValue) => {
    await router.isReady()
    const query = route.query
    if (oldValue !== newValue) {
      router.replace({ query: { ...query, search: newValue } })
    }
  })

  const analysisFunctionsWithResults = computed(() => {
    const analysisFunctions = analysisFunctionsStore.analysisFunctions

    if (selectedComponentInProject.value === null || analysisFunctions === null) {
      return null
    }

    const instances = vuexStore.getters['analysis_instances/getInstancesForSelectedComponentInProject'] as InstanceConfig[]
    return combineAnalysisFunctionsWithInstanceResults(analysisFunctions, instances, selectedComponentInProjectResults.value?.result_ids ?? [])
  })

  const selectedComponentsMappedPinHashIds = computed(() => {
    if (!selectedComponentInProject.value) {
      return null
    }

    return selectedComponentInProject.value.pins?.map((pin) => {
      return pin.datapoint_hash_id!
    }) ?? []
  })

  const selectedComponentsMappedPinNames = computed(() => {
    if (!selectedComponentInProject.value) {
      return null
    }

    const result: Map<string, string> = new Map()
    selectedComponentInProject.value.pins?.forEach((pin) => {
      result.set(pin.dataPointID!, textForLocale(pin.nameDE!, pin.nameEN!))
    })
    return result
  })

  function clearComponentSpecificData () {
    vuexStore.state.analysis_instances.analysisResult = null
    selectedInstanceId.value = null
    selectedResultId.value = null
  }

  async function fetchFullComponentInProject (payload: { componentInProjectId: number }) {
    loadingComponentInProject.value = true

    try {
      selectedComponentInProject.value = await projectApiStore.getProjectComponent({ projectId: appStore.projectId, projectComponentId: payload.componentInProjectId })
    } catch (error) {
      reportError(error)
    } finally {
      loadingComponentInProject.value = false
    }
  }

  async function fetchComponentsAnalysisResults () {
    loadingComponentsAnalysisResults.value = true

    try {
      const componentInProjectId = validateNotNullish(selectedComponentInProject.value?.id)
      const user = validateNotNullish(userStore.userDetails)
      selectedComponentInProjectResults.value = await analyticsApiStore.getProjectComponentResults({
        projectComponentId: componentInProjectId,
        projectId: appStore.projectId,
        language: i18n.global.locale.value,
        unitsSystem: user.units_system,
        currencySystem: user.currency_system,
      })
    } catch (error: unknown) {
      if ((error as Response).status !== 400) {
        showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.analysis_results') })}`)
        reportError(error)
        selectedComponentInProjectResults.value = null
      } else {
        selectedComponentInProjectResults.value = {
          component_in_project: selectedComponentInProject.value ?? undefined,
          result_ids: [],
        }
      }
    } finally {
      loadingComponentsAnalysisResults.value = false
    }
  }

  async function selectComponentInProject (componentInProjectId: number) {
    if (selectedComponentInProject.value === null || selectedComponentInProject.value.id !== componentInProjectId) {
      await fetchFullComponentInProject({ componentInProjectId })
      const newlySelectedComponent = validateNotNullish(
        selectedComponentInProject.value,
        { errorMessage: i18n.global.t('notifications.errors.no_component_in_project') },
      )

      analysisFunctionsStore.fetchAnalysisFunctions({ component_id: newlySelectedComponent.component!.id! })
      fetchComponentsAnalysisResults()
      clearComponentSpecificData()
    }
  }

  function unselectComponentInProject () {
    selectedComponentInProject.value = null
    clearComponentSpecificData()
  }

  async function ensureAnyComponentInProjectIsSelected () {
    if (!selectedComponentInProject.value) {
      const firstComponentInProjectId = vuexStore.getters['components_in_project/firstComponentInProjectId'] as number | null
      if (firstComponentInProjectId) {
        await selectComponentInProject(firstComponentInProjectId)
      } else {
        clearComponentSpecificData()
      }
    }
  }

  return {
    analysisFunctionsWithResults,
    clear,
    dateRange,
    ensureAnyComponentInProjectIsSelected,
    followAnalysisPeriod,
    hiddenPinsHashIds,
    loadingComponentInProject,
    loadingComponentsAnalysisResults,
    samplerate,
    search,
    selectComponentInProject,
    selectedComponentInProject,
    selectedComponentInProjectResults,
    selectedComponentsMappedPinNames,
    selectedComponentsMappedPinHashIds,
    selectedInstanceId,
    selectedResultId,
    unselectComponentInProject,
  }
})
