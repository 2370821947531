<script setup lang="ts">
import ColoredFigure from '@/components/ColoredFigure.vue'
import { computed } from 'vue'
import { getBackgroundAndFigureColors } from '@/utils/helpers/colors'
import { useI18n } from 'vue-i18n'
import type { PerSquareUnitKPIsKeys } from '@/stores/views/Reporting'

export interface AssetBaseCardProps {
  color?: string,
  kpi: PerSquareUnitKPIsKeys,
  loading: boolean,
  selected: boolean,
  subtitle?: string,
  title: string,
  unit?: string,
  value?: string
}

const props = withDefaults(defineProps<AssetBaseCardProps>(), {
  loading: false,
  selected: false,
  value: '',
})

const { t } = useI18n()

const coloredFigureStyle = computed(() => {
  const backgroundAndFigureColors = getBackgroundAndFigureColors(props.color)

  return {
    backgroundColor: backgroundAndFigureColors.backgroundColor,
    figureColor: backgroundAndFigureColors.figureColor,
  }
})

const coloredFigureValue = computed<string>(() => {
  return props.value !== '' ? props.value : 'fa:far fa-exclamation-triangle'
})

const coloredFigureNoDataText = computed(() => {
  if (props.value !== '') return

  if (props.kpi === 'productivity' || props.kpi === 'technical_availability') {
    return t('no_data_ta_wellbeing')
  } else {
    return t('no_data_energy')
  }
})

const isHoverable = computed(() => {
  return !props.loading && props.value !== ''
})
</script>

<template>
  <v-card
    v-if="!props.loading"
    :class="['tw-p-6', { 'aedifion-active': props.selected }, { 'aedifion-hover': isHoverable }]"
  >
    <v-card-title
      data-testid="title"
      class="tw-p-0"
    >
      {{ title }}
    </v-card-title>
    <v-card-subtitle
      v-if="props.subtitle"
      data-testid="subtitle"
      class="text-body-1 tw-p-0 tw-pt-1"
    >
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text class="tw-p-0 tw-pt-6">
      <ColoredFigure
        :value="coloredFigureValue"
        :icon="!props.value"
        :unit
        :text="coloredFigureNoDataText"
        :background-color="coloredFigureStyle.backgroundColor"
        :figure-color="coloredFigureStyle.figureColor"
      />
      <slot />
    </v-card-text>
  </v-card>
  <v-skeleton-loader
    v-else
    height="156"
    data-testid="skeleton-loader"
    class="tw-p-6"
    type="list-item-two-line, button"
  />
</template>

<i18n lang="json" locale="de">
{
  "no_data_ta_wellbeing": "Keine Daten verfügbar. Bitte überprüfen Sie, ob Status- und Alarmseite aktiviert sind oder kontaktieren Sie uns.",
  "no_data_energy": "Keine Daten verfügbar. Bitte überprüfen Sie, ob Zähler eingerichtet sind."
}
</i18n>
<i18n lang="json" locale="en">
{
  "no_data_ta_wellbeing": "No data available. Please check if status & alert page activated or contact us.",
  "no_data_energy": "No data available. Please check if meters are set up."
}
</i18n>

<style lang="sass" scoped>
:deep(.v-skeleton-loader)
  padding: 24px !important
:deep(.v-skeleton-loader__button)
  max-width: 150px
  width: 150px
  height: 32px
  margin: 0
:deep(.v-skeleton-loader__list-item-two-line)
  margin: 0
:deep(.v-skeleton-loader__text)
  margin: 0 0 4px 0
</style>
