<i18n lang="json" locale="de">
  {
    "search": {
      "label": "Projekt suchen"
    }
  }
</i18n>

<i18n lang="json" locale="en">
  {
    "search": {
      "label": "Search project"
    }
  }
</i18n>

<template>
  <div class="layout-wrapper">
    <PageHeader
      title-key="links.meta.title.home"
    >
      <SearchInput
        class="search tw-grow-0 tw-ml-auto tw-w-[320px]"
        :disabled="isLoading"
        :placeholder="t('search.label')"
        :model-value="search"
        @update:model-value="search = $event"
      />
    </PageHeader>
    <v-row no-gutters>
      <v-col class="pa-0">
        <CardsBlock
          :search="search"
        />
      </v-col>
      <v-fab-transition>
        <v-btn
          v-show="scrollPosition > 150"
          class="fab"
          color="primary-darken2"
          icon
          theme="dark"
          size="48"
          @click="toTop"
        >
          <v-icon theme="dark">
            fa:far fa-arrow-to-top
          </v-icon>
        </v-btn>
      </v-fab-transition>
    </v-row>
  </div>
</template>

<script lang="ts">
import CardsBlock from '@/views/Portfolio/CardsBlock.vue'
import { defineComponent } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import SearchInput from '@/components/SearchInput.vue'
import throttle from 'lodash.throttle'
import { useI18n } from 'vue-i18n'
import { useOverviewStore } from '@/stores/views/Overview/overview'

type DebouncedFunction = ReturnType<typeof throttle>

export default defineComponent({
  name: 'Portfolio',

  components: {
    CardsBlock,
    PageHeader,
    SearchInput,
  },

  setup () {
    const { t } = useI18n()
    return { overviewStore: useOverviewStore(), t }
  },

  data () {
    return {
      menu: false,
      scrollPosition: 0,
      search: '',
      throttledScroll: null as DebouncedFunction|null,
    }
  },

  computed: {
    isLoading (): boolean {
      return this.$store.getters['projects/isLoading']
    },
  },

  mounted () {
    this.throttledScroll = throttle(this.handleScroll, 100)
    window.addEventListener('scroll', this.throttledScroll)
  },

  beforeUnmount () {
    if (this.throttledScroll) {
      window.removeEventListener('scroll', this.throttledScroll)
    }
  },

  methods: {
    handleScroll (): void {
      this.scrollPosition = window.scrollY
    },

    toTop (): void {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    },
  },
})
</script>

<style lang="sass">
  .fab
    position: fixed
    bottom: 12px
    right: 12px
</style>
