import { type Conversation, type ConversationEntry, type ConversationList } from '@aedifion.io/aedifion-api'
import { type GetUserConversationPayload, type GetUserConversationsPayload, type PostChatPayload, type PostResponseRatingPayload, useAiApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { reportError } from '@/utils/helpers/errors'
import { showErrorNotification } from '@/utils/helpers/notifications'
import i18n from '@/i18n'

export type CurrentChat = Array<{
  request: string
  response: ConversationEntry
}>

export const useAiStore = defineStore('ai', () => {
  const aiApiStore = useAiApiStore()
  const projectApiStore = useProjectApiStore()

  const loadingUserConversation = ref(false)
  const loadingUserConversations = ref(false)
  const updatingRating = ref(false)
  const updatingConversation = ref(false)
  const postingChat = ref(false)
  const userConversations = ref<ConversationList | null>(null)
  const userConversation = ref<Conversation | null>(null)
  const currentChat = ref<CurrentChat>(JSON.parse(localStorage.getItem('currentChat') ?? '[]'))

  // Watch for changes to currentChat and update localStorage
  watch(currentChat, (newChat) => {
    localStorage.setItem('currentChat', JSON.stringify(newChat))
  }, { deep: true })

  async function getUserConversations (payload: GetUserConversationsPayload) {
    loadingUserConversations.value = true
    try {
      userConversations.value = await aiApiStore.getUserConversations(payload)
    } catch (error) {
      reportError(error)
      const errorMessage = i18n.global.t('notifications.errors.fetch', {
        resource: i18n.global.t('notifications.resources.conversations'),
      })
      showErrorNotification(errorMessage)
    } finally {
      loadingUserConversations.value = false
    }
  }

  async function getUserConversation (payload: GetUserConversationPayload) {
    loadingUserConversation.value = true
    try {
      userConversation.value = await aiApiStore.getUserConversation(payload)
    } catch (error) {
      reportError(error)
      const errorMessage = i18n.global.t('notifications.errors.fetch', {
        resource: i18n.global.t('notifications.resources.conversation'),
      })
      showErrorNotification(errorMessage)
    } finally {
      loadingUserConversation.value = false
    }
  }

  async function postRating (payload: PostResponseRatingPayload) {
    updatingRating.value = true
    try {
      await aiApiStore.postRating(payload)
    } catch (error) {
      reportError(error)
      const errorMessage = i18n.global.t('notifications.errors.update', {
        resource: i18n.global.t('notifications.resources.rating'),
      })
      showErrorNotification(errorMessage)
    } finally {
      updatingRating.value = false
    }
  }

  async function postChat (payload: PostChatPayload) {
    postingChat.value = true
    try {
      currentChat.value.push({ request: payload.body.content, response: {} as ConversationEntry })
      const result = await projectApiStore.postChat(payload)
      if (result) {
        currentChat.value.at(-1)!.response = filterDuplicateSources(result.entry)
      }
    } catch (error) {
      reportError(error)
      const errorMessage = i18n.global.t('notifications.errors.update', {
        resource: i18n.global.t('notifications.resources.conversation'),
      })
      showErrorNotification(errorMessage)
      // @ts-ignore
      currentChat.value.at(-1).response = {
        content: i18n.global.t('ai_assistant.error_message'),
        timestamp: new Date(),
      }
    } finally {
      postingChat.value = false
    }
  }

  function clearCurrentChat () {
    currentChat.value = []
  }

  function clearCachedChat () {
    localStorage.removeItem('currentChat')
  }

  function filterDuplicateSources (conversation: ConversationEntry) {
    const sources = new Set<string>()
    const filteredConversation = { ...conversation }

    filteredConversation.sources = conversation.sources.filter((source) => {
      if (sources.has(source.url)) {
        return false
      }

      sources.add(source.url)
      return true
    })

    return filteredConversation
  }

  return {
    currentChat,
    clearCachedChat,
    loadingUserConversation,
    loadingUserConversations,
    updatingConversation,
    updatingRating,
    userConversations,
    userConversation,
    getUserConversations,
    getUserConversation,
    postRating,
    postChat,
    postingChat,
    clearCurrentChat,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAiStore, import.meta.hot))
}
