import { aedifionApi, AnalyticsResultsForComponent, ComponentInProject, ComponentInProjectWithContext, Result, User } from '@aedifion.io/aedifion-api'
import { sampleDataForBuildingComponent, sampleDataForComponentResults } from './sampleData'
import { ActionTree } from 'vuex'
import { BuildingAnalysesState } from './types'
import i18n from '@/i18n'
import { ProjectValue } from '@/utils/types'
import { reportError } from '@/utils/helpers/errors'
import { resetStoreState } from './state'
import { RootState } from '../types'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { useUserStore } from '@/stores/user'
import { validateNotNullish } from '@/utils/helpers/validate'

export async function fetchBuildingComponent (projectId: number): Promise<ComponentInProjectWithContext|null> {
  const projectBuildingComponentResponse = await aedifionApi.Project.getProjectComponents(projectId, undefined, undefined, 'building', 'alphanumeric_id=B', 'asc', 'nameEN')
  if (!projectBuildingComponentResponse.items || projectBuildingComponentResponse.items?.length === 0) {
    return null
  }
  const buildingComponent = projectBuildingComponentResponse.items[0]
  const buildingComponentId = validateNotNullish(buildingComponent.id)
  return await aedifionApi.Project.getProjectComponent(projectId, buildingComponentId)
}

export default {
  clear: ({ state }) => {
    resetStoreState(state)
  },

  fetchBuildingAnalysesForProject: async ({ state, commit, getters }, projectId: number) => {
    if (!(projectId in state.buildingComponents)) {
      return
    }
    commit('SET_LOADING_PROJECTS_BUILDING_ANALYSES', { projectId, value: true } as ProjectValue<boolean>)
    try {
      let projectBuildingComponentResults: AnalyticsResultsForComponent
      if (getters.projectUsesSampleData(projectId)) {
        projectBuildingComponentResults = sampleDataForComponentResults(i18n.global.locale.value)
      } else {
        const buildingComponent = getters.buildingComponentOfProject(projectId) as ComponentInProjectWithContext|null
        const buildingComponentId = buildingComponent!.id!
        const userStore = useUserStore()
        const user: Readonly<User> = validateNotNullish(userStore.userDetails)
        projectBuildingComponentResults = await aedifionApi.Analytics.getComponentResults(
          buildingComponentId,
          projectId,
          i18n.global.locale.value,
          user.units_system,
          user.currency_system,
        )
      }
      commit('SET_BUILDING_ANALYSES_RESULTS', { projectId, value: projectBuildingComponentResults } as ProjectValue<AnalyticsResultsForComponent>)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.digital_twin') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_PROJECTS_BUILDING_ANALYSES', { projectId, value: false } as ProjectValue<boolean>)
    }
  },

  fetchBuildingComponentForProject: async ({ commit }, projectId: number) => {
    commit('SET_LOADING_PROJECTS_BUILDING_COMPONENT', { projectId, value: true } as ProjectValue<boolean>)
    try {
      let buildingComponent: ComponentInProjectWithContext|null = await fetchBuildingComponent(projectId)
      if (buildingComponent === null) {
        buildingComponent = sampleDataForBuildingComponent(projectId)
        commit('ADD_PROJECT_WITH_SAMPLE_DATA', projectId)
      }
      commit('SET_BUILDING_COMPONENT', { projectId, value: buildingComponent } as ProjectValue<ComponentInProjectWithContext>)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.digital_twin') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_PROJECTS_BUILDING_COMPONENT', { projectId, value: false } as ProjectValue<boolean>)
    }
  },

  fetchOrCreateBuildingComponentForProject: async ({ commit }, projectId: number) => {
    commit('SET_LOADING_PROJECTS_BUILDING_COMPONENT', { projectId, value: true } as ProjectValue<boolean>)
    try {
      let buildingComponentLookup: ComponentInProject[] = (await aedifionApi.Project.getProjectComponents(projectId, undefined, undefined, 'building', 'alphanumeric_id=B', 'asc', 'nameEN')).items!
      if (buildingComponentLookup.length === 0) {
        const components = await aedifionApi.Component.getComponents()
        const componentId: number|undefined = components.find(item => item.alphanumeric_id === 'B')!.id
        const body = {
          nameDE: 'Gebäude',
          nameEN: 'Building',
        }
        const newBuildingComponent = (await aedifionApi.Project.postProjectComponent(projectId, componentId!, body)) as Result<ComponentInProject>
        buildingComponentLookup = [newBuildingComponent.resource]
      }
      const buildingComponent: ComponentInProjectWithContext = await aedifionApi.Project.getProjectComponent(projectId, buildingComponentLookup[0].id!)
      commit('SET_BUILDING_COMPONENT', { projectId, value: buildingComponent } as ProjectValue<ComponentInProjectWithContext>)
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.fetch', { resource: i18n.global.t('notifications.resources.digital_twin') })}`)
      reportError(error)
    } finally {
      commit('SET_LOADING_PROJECTS_BUILDING_COMPONENT', { projectId, value: false } as ProjectValue<boolean>)
    }
  },
} as ActionTree<BuildingAnalysesState, RootState>
