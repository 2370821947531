import { AnalysisResult, InstanceConfig } from '@aedifion.io/aedifion-api'
import { AnalysisInstancesState } from './types'
import { GetterTree } from 'vuex'
import { RootState } from '../types'
import { useOptimizationStore } from '@/stores/optimization'

export default {
  analysisResult: (state: AnalysisInstancesState): AnalysisResult|null => state.analysisResult,

  getInstances: (state: AnalysisInstancesState): InstanceConfig[] => state.instanceList,

  getInstancesForSelectedComponentInProject: (state: AnalysisInstancesState): InstanceConfig [] => {
    const optimizationStore = useOptimizationStore()
    const selectedComponentInProjectId: number|null = optimizationStore.selectedComponentInProject?.id ?? null
    if (selectedComponentInProjectId === null) return []
    return state.instanceList.filter((instance: InstanceConfig) => instance.config.componentinproject_id === selectedComponentInProjectId)
  },

  getSelectedInstance: (state: AnalysisInstancesState): InstanceConfig|null => state.selectedInstance,

  getSelectedInstanceId: (state: AnalysisInstancesState): number|null => state.selectedInstance?.id || null,

  isLoadingAnalysisResult: (state: AnalysisInstancesState): boolean => state.loadingAnalysisResult,

  isLoadingInstances: (state: AnalysisInstancesState): boolean => state.loading,

  pendingInstanceUpdates: (state: AnalysisInstancesState): number[] => state.pendingInstanceUpdates,
} as GetterTree<AnalysisInstancesState, RootState>
