<script setup lang="ts">
import VueMarkdown from 'vue-markdown-render'
import { ref } from 'vue'
import ResponseSource from './ResponseSource.vue'
import type { CurrentChat } from '@/stores/views/AiAssistant'

interface Props {
  message: CurrentChat[number]
}

const props = defineProps<Props>()

const messageContent = ref(props.message.response.content)

</script>

<template>
  <div class="tw-flex tw-flex-col">
    <span
      data-testid="message-box-request"
      class="text-body-1 text-neutral-darken3 tw-ml-auto tw-mb-6"
    >{{ message.request }}</span>
    <div
      v-if="messageContent"
      class="tw-py-3 tw-px-4 tw-bg-white tw-mb-6 tw-rounded-2xl tw-border-[rgb(var(--v-theme-neutral-lighten1))] tw-border"
    >
      <VueMarkdown
        id="message-box"
        data-testid="message-box-response"
        :source="messageContent"
      />
      <div class="tw-flex tw-flex-col tw-gap-2 tw-mt-3">
        <ResponseSource
          v-for="source in props.message.response.sources"
          :key="source.url"
          data-testid="response-source"
          :source="source"
        />
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
#message-box
  color: rgb(var(--v-theme-neutral-darken3))
  font-size: 14px
  line-height: 20px
</style>
