import { type ComponentInProjectWithContext } from '@aedifion.io/aedifion-api'
import { getComponentAttribute } from '@/utils/helpers/componentAttribute'
import store from '@/vuex'

export const getPortfolioFloorArea = (projectsIds: number[]): string|null => {
  if (!Array.isArray(projectsIds) || projectsIds.length === 0) return null

  const floorAreas = projectsIds.map((projectId) => {
    const buildingComponent: ComponentInProjectWithContext = store.getters['building_analyses/buildingComponentOfProject'](projectId)
    return getComponentAttribute(buildingComponent, 'B+NFA')?.value
  })

  const totalFloorArea = floorAreas.reduce((sum, floorArea) => (sum as number) + (Number(floorArea ?? '') ?? 0), 0)

  return totalFloorArea ? `${totalFloorArea}` : null
}
