import i18n from '@/i18n'
import { showErrorNotification } from '@/utils/helpers/notifications'
import { AnalysisFunction } from '@aedifion.io/aedifion-api'
import { useAnalyticsApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { defineStore } from 'pinia'
import { reportError } from '@/utils/helpers/errors'
import { computed, ref } from 'vue'

export type FetchAnalysisFunctionsPayload = {
  component_id: number;
}

export const useAnalysisFunctionsStore = defineStore('analysisFunctions', () => {
  const analyticsApiStore = useAnalyticsApiStore()

  const analysisFunctionsList = ref<AnalysisFunction[] | null>(null)
  const loading = ref(false)

  function clear () {
    analysisFunctionsList.value = null
    loading.value = false
  }

  async function fetchAnalysisFunctions (payload: FetchAnalysisFunctionsPayload) {
    loading.value = true
    try {
      analysisFunctionsList.value = await analyticsApiStore.getFunctions({
        componentAlphanumericId: undefined,
        componentId: payload.component_id,
      })
    } catch (error) {
      showErrorNotification(`${i18n.global.t('notifications.errors.functions.fetch')}`)
      reportError(error)
    } finally {
      loading.value = false
    }
  }

  const analysisFunctionById = computed(() => (id: number): AnalysisFunction | null => {
    return analysisFunctionsList.value?.find((analysisFunction) => analysisFunction.id === id) ?? null
  })

  return {
    analysisFunctionById,
    analysisFunctions: analysisFunctionsList,
    clear,
    fetchAnalysisFunctions,
    loading,
  }
})
