import { computed, Ref, ref } from 'vue'
import { acceptHMRUpdate, defineStore } from 'pinia'
import i18n from '@/i18n'
import { useControlsApiStore, useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import { validateNotNullish } from '@/utils/helpers/validate'
import type { Quotas } from '@aedifion.io/aedifion-api'
import { reportError } from '@/utils/helpers/errors'

// TODO: refactor this store. For instance `currentProjectHasControlsApp` should
// be a computed that relies on `currentProjectControlsAppCount`.
// `setProjectId()` should be free of side-effects and not be async.
export const useAppStore = defineStore('app', () => {
  const controlsApiStore = useControlsApiStore()
  const projectApiStore = useProjectApiStore()

  const currentProjectId: Ref<number|null> = ref(null)
  const currentProjectHasControlsApp = ref(false)
  const currentProjectControlsAppCount: Ref<number|null> = ref(null)
  const projectQuota = ref<Quotas | null>(null)

  async function projectHasControlsApp (projectId: number): Promise<boolean> {
    try {
      const controlsAppsFetchResult = await controlsApiStore.getControlsApps({ perPage: 1, projectId })
      currentProjectControlsAppCount.value = controlsAppsFetchResult.meta.total_items
      return controlsAppsFetchResult.meta.total_items > 0
    } catch (error) {
      return false
    }
  }

  const projectId = computed((): number => {
    return validateNotNullish(currentProjectId.value, { errorMessage: i18n.global.t('notifications.errors.no_project_selected') })
  })

  async function setProjectId (value: number): Promise<void> {
    currentProjectId.value = value
    getProjectQuota()
    currentProjectControlsAppCount.value = null
    currentProjectHasControlsApp.value = await projectHasControlsApp(value)
  }

  async function getProjectQuota () {
    try {
      const response = await projectApiStore.getQuotas({ projectId: projectId.value })

      projectQuota.value = response
    } catch (error) {
      reportError(error)
    }
  }

  const isAiAssistantEnabledForCurrentProject = computed(() => {
    return projectQuota.value?.ai?.quota_available?.assistant?.enabled === true
  })

  return {
    currentProjectControlsAppCount,
    currentProjectHasControlsApp,
    projectHasControlsApp,
    projectQuota,
    isAiAssistantEnabledForCurrentProject,
    projectId,
    setProjectId,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
