import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { TimeseriesWithContext } from '@aedifion.io/aedifion-api'
import { useAppStore } from '@/stores/app'
import { useProjectApiStore } from '@aedifion.io/pinia-aedifion-api-stores'
import vuexStore from '@/vuex'

export interface PinValue {
  unitSymbol: string,
  value: number | undefined
}

export const useAIControlsAppPinStore = defineStore('AIControlsAppPin', () => {
  const appStore = useAppStore()
  const projectApiStore = useProjectApiStore()

  const currentPinValues = ref<Record<string, PinValue>>({})

  async function fetchPinValues (dataPointIds: string[]): Promise<void> {
    currentPinValues.value = {} // clear previous data

    const pinValueRequests: TimeseriesWithContext[] = await projectApiStore.getTimeseries({
      datapointIds: dataPointIds,
      projectId: appStore.projectId,
      max: 1,
      end: new Date(),
    })

    pinValueRequests.forEach((response) => {
      const unitSymbol: string = response.units ? vuexStore.getters['labels/label']('units', response.units)?.symbol || '' : ''
      currentPinValues.value[response.dataPointID!] = {
        unitSymbol,
        value: response.data?.[0]?.value,
      }
    })
  }

  return { currentPinValues, fetchPinValues }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAIControlsAppPinStore, import.meta.hot))
}
