<script setup lang="ts">
import { computed, onBeforeUnmount, ref, useTemplateRef, watch } from 'vue'
import AiIcon from './AiIcon.vue'
import { useUserStore } from '@/stores/user'
import { useI18n } from 'vue-i18n'
import { type CurrentChat, useAiStore } from '@/stores/views/AiAssistant'
import { useAppStore } from '@/stores/app'
import { v4 as uuidv4 } from 'uuid'
import MessageBox from './MessageBox.vue'
import LoadingIndicator from './LoadingIndicator.vue'

const showAIAssistant = defineModel({
  type: Boolean,
  default: false,
})

const { t } = useI18n()

const HEADER_BUTTONS = [
  // TODO: Add history button
  // { icon: 'fa:far fa-history', class: 'tw-m-3 tw-border-[rgb(var(--v-theme-neutral-lighten1))]', disabled: false, variant: 'elevated', handler: () => console.log('History clicked') },
  { icon: 'fa:far fa-message-plus', class: 'tw-m-3 tw-border-[rgb(var(--v-theme-neutral-lighten1))]', disabled: false, variant: 'elevated', handler: () => handleCreateNewChat() },
  { icon: 'fa:far fa-xmark-large', class: 'tw-ml-auto tw-m-3 tw-border-[rgb(var(--v-theme-neutral-lighten1))]', disabled: false, variant: 'outlined', handler: () => { showAIAssistant.value = false } },
] as const

const chat = ref('')

const currentChat = computed<CurrentChat>(() => aiStore.currentChat)

const aiStore = useAiStore()
const appStore = useAppStore()

const currentChatId = ref(uuidv4())

const handleSendMessage = () => {
  if (!chat.value.trim()) return

  aiStore.postChat({
    body: {
      content: chat.value,
    },
    conversation_id: currentChatId.value,
    projectId: appStore.projectId,
  })
  chat.value = ''
}

const showTooltip = ref(true)

const isSendButtonDisabled = computed(() => chat.value.trim().length === 0 || aiStore.postingChat)

const showAIAssistantIcon = ref(true)

const userStore = useUserStore()
const userFirstName = computed<string>(() => userStore.user?.user?.firstName ?? '')

const handleCreateNewChat = () => {
  currentChatId.value = uuidv4()
  chat.value = ''
  aiStore.clearCurrentChat()
  aiStore.clearCachedChat()
}

// #region SCROLLING TO BOTTOM OF CHAT
const chatContainer = useTemplateRef<HTMLDivElement>('chatContainer')

watch(currentChat, () => {
  if (currentChat.value.length && chatContainer.value) {
    scrollToBottomOfChat()
  }
}, {
  immediate: true,
  deep: true,
})

const scrollTimeout = ref<number|null>(null)

function scrollToBottomOfChat (behavior: ScrollBehavior = 'smooth', delay = 0) {
  if (chatContainer.value) {
    if (scrollTimeout.value) {
      clearTimeout(scrollTimeout.value)
    }
    scrollTimeout.value = setTimeout(() => {
      chatContainer.value!.scrollTo({
        top: chatContainer.value!.scrollHeight,
        behavior,
      })
      scrollTimeout.value = null
    }, delay)
  }
}

onBeforeUnmount(() => {
  if (scrollTimeout.value) {
    clearTimeout(scrollTimeout.value)
  }

  if (scrimTimeout.value) {
    clearTimeout(scrimTimeout.value)
  }
})

watch(showAIAssistant, (value) => {
  if (value) {
    scrollToBottomOfChat('instant', 300)
  }
})

const showScrim = ref(false)
const scrimTimeout = ref<number|null>(null)

watch(showAIAssistant, (value) => {
  if (value) {
    setTimeout(() => {
      showScrim.value = true
    }, 200)
  } else {
    showScrim.value = false
  }
})
// #endregion
</script>

<template>
  <v-tooltip
    v-model="showTooltip"
    content-class="aedifion-tooltip"
    location="left"
  >
    <template #activator="{props: tooltipProps}">
      <AiIcon
        v-show="!showAIAssistant"
        v-bind="tooltipProps"
        fixed
        @click="showAIAssistant = true"
      />
    </template>
    <span>
      {{ t('ask_ai') }}
    </span>
  </v-tooltip>
  <Transition
    name="slide-right"
  >
    <div
      v-show="showAIAssistant"
      :class="['tw-fixed tw-inset-0 tw-z-50 tw-border-1 tw-border-[rgb(var(--v-theme-neutral-lighten1))]', { 'scrim': showScrim }]"
    >
      <div
        class="tw-fixed tw-flex tw-flex-col tw-h-full tw-align-middle tw-w-[560px] tw-right-0 tw-bottom-0 bg-neutral-lighten3"
        transition="slide-y-transition"
      >
        <!-- HEADER SECTION -->
        <div
          class="tw-bg-white tw-flex tw-border-b tw-border-r-0 tw-border-[rgb(var(--v-theme-neutral-lighten1))]"
        >
          <v-btn
            v-for="button in HEADER_BUTTONS"
            :key="button.icon"
            :class="button.class"
            density="compact"
            :variant="button.variant"
            size="small"
            rounded
            icon
            :disabled="button.disabled"
            @click="button.handler"
          >
            <v-icon size="14">
              {{ button.icon }}
            </v-icon>
          </v-btn>
        </div>
        <!-- CHAT SECTION -->
        <div
          ref="chatContainer"
          class="tw-overflow-auto tw-flex-grow tw-p-6"
        >
          <MessageBox
            v-for="message in currentChat"
            :key="message.response.id"
            :message
          />
          <LoadingIndicator
            v-if="aiStore.postingChat"
          />
        </div>
        <!-- AI ICON -->
        <div :class="['tw-m-auto tw-flex-grow', {'tw-top-3 tw-left-[50%] tw-translate-x-[-50%] tw-absolute' : currentChat.length}]">
          <AiIcon
            v-model="showAIAssistantIcon"
            class="tw-m-auto tw-block"
          />
          <div
            v-if="!currentChat.length"
            class="tw-text-center tw-mt-2 text-body-1"
          >
            <span>Hi {{ userFirstName }}!</span> <br>
            <span>{{ t('help_text') }}</span>
          </div>
        </div>
        <v-textarea
          v-model="chat"
          variant="outlined"
          rows="2"
          class="tw-mt-auto tw-flex-grow-0 tw-p-4 tw-pt-0"
          hide-details
          no-resize
          :placeholder="t('message')"
          @keydown.enter.prevent="handleSendMessage"
        >
          <template #append-inner>
            <button
              icon
              :class="['tw-mt-4 tw-flex tw-justify-end', { 'tw-text-[rgb(var(--v-theme-neutral))]': isSendButtonDisabled }]"
              :disabled="isSendButtonDisabled"
              @click="handleSendMessage"
            >
              <v-icon
                size="14"
                class="tw-mr-2"
              >
                fa:far fa-paper-plane
              </v-icon>
            </button>
          </template>
        </v-textarea>
      </div>
    </div>
  </Transition>
</template>

<style lang="sass" scoped>
.scrim
  background-color: rgba(var(--v-theme-neutral), 0.5)
  backdrop-filter: blur(4px)
  transition: background-color 0.3s

.slide-right-enter-active
  transition: transform 0.3s ease
.slide-right-leave-active
  transition: transform 0.6s ease
.slide-right-enter-from
 transform: translateX(100%)
.slide-right-leave-to
  transform: translateX(200%)

:deep(.v-field--active)
  > .v-field__overlay
    box-shadow: 0px 0px 0px 3px rgba(23, 25, 38, 0.12) !important
    border-color: rgb(var(--v-theme-neutral)) !important
  > .v-field__outline
    --v-field-border-width: 1px !important
    color: rgb(var(--v-theme-neutral-darken2)) !important

:deep(textarea)
  font-size: 14px !important
  line-height: 20px
  color: rgb(var(--v-theme-neutral-darken3))
</style>

<i18n lang="json" locale="de">
  {
    "help_text": "Wie kann ich Ihnen weiterhelfen?",
    "ask_ai": "Fragen Sie unseren KI-Assistenten",
    "message": "Nachricht"
  }
</i18n>
<i18n lang="json" locale="en">
  {
    "help_text": "How can I help you today?",
    "ask_ai": "Ask our AI Assistant",
    "message": "Message"
  }
</i18n>
